import React, { Component } from 'react';
import Cookies from 'js-cookie';
import oldCookieStyles from './cookie.module.css';
import newCookieStyles from './cookie-rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';

class CookieConsent extends Component {

  constructor() {
    super();

    this.state = {
      showCookieDisclaimer: true
    }
  }

  componentDidMount() {
    var consented = Cookies.get('cookieConsent');

    if (consented) {
      this.setState({
        showCookieDisclaimer: false
      })
    }
  }

  /**
   * Hides the cookie disclaimer bar
   * Sets cookie to keep track of consent on disclaimer
   */
  setCookie = () => {
    Cookies.set('cookieConsent', true);
    this.setState({
      showCookieDisclaimer: false
    })
  }

  render() {
    const styles = isRebranded() ? newCookieStyles : oldCookieStyles;
    const cookieDisclaimer = this.props.content.cookieConsent;

    // Set custom Chinese accept text when in a Chinese locale
    const getAcceptText = () => {
      let acceptText = "I Accept";
      const locale = this.props?.content?.contentLocale;
      if (locale === 'zh-CN') {
        acceptText = "我接受";
      }
      else if (locale === 'zh-TW') {
        acceptText = "我接受";
      }
      return acceptText;
    }

    const getCookieText = () => {
      return { __html: cookieDisclaimer };
    }

    return (
      <div>
        {this.state.showCookieDisclaimer ?
          <div className={styles.cookieNotification}>
            <div className={styles.cookieContainer}>
              <div className={styles.cookieText} dangerouslySetInnerHTML={getCookieText()}></div>
              <button onClick={this.setCookie} className={styles.cookieButton}>{getAcceptText()}</button>
            </div>
          </div> : ''}
      </div>
    );
  }
}

export default CookieConsent; 