// Assume if the GATSBY_IS_RAAS env var isn't empty or false that we want to use Raas endpoint
export const useRaas = () => {
    return (process.env.GATSBY_IS_RAAS !== undefined && process.env.GATSBY_IS_RAAS !== null &&
        process.env.GATSBY_IS_RAAS !== '' && process.env.GATSBY_IS_RAAS.toLocaleLowerCase() !== 'false'
        );
}

export const isRebranded = () => {
    return (process.env.GATSBY_IS_REBRANDED
         && process.env.GATSBY_IS_REBRANDED.toUpperCase() === 'TRUE');
}