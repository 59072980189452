import React, { Component } from 'react';
import oldFooterStyles from './footer.module.css';
import newFooterStyles from './footer-rebrand.module.css'
import { isRebranded } from '../../utils/featureFlagHelpers';
class Footer extends Component {
  render() {
    const content = this.props.content;
    const pageHasShortContent = this.props.shortPageClass;
    const footerStyles = isRebranded() ? newFooterStyles : oldFooterStyles;

    const getTermsLink = () => {
      return { __html: content.terms };
    }

    const getPrivacyLink = () => {
      return { __html: content.privacy };
    }

    const getSecurityLink = () => {
      return { __html: content.security };
    }

    return (
      <section className={pageHasShortContent ? footerStyles.c2foFooter__bottom : footerStyles.c2foFooter }>
        <div className={footerStyles.c2foFooter__container}>
          <div className={footerStyles.c2foFooter__links}>
            <div className={footerStyles.c2foFooter__linkContainer} dangerouslySetInnerHTML={getPrivacyLink()}></div>
            <div className={footerStyles.c2foFooter__linkContainer}dangerouslySetInnerHTML={getTermsLink()}></div>
            <div className={footerStyles.c2foFooter__linkContainer} dangerouslySetInnerHTML={getSecurityLink()}></div>
          </div>
          <div className={footerStyles.c2foCopyright}>
            <p>{content.copyright}</p >
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;