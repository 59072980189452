import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { isRebranded } from '../../utils/featureFlagHelpers';

class Head extends Component {

  constructor(props) {
    super(props)

    this.state = {
      langauge: 'en-US',
      description: props.description || `The C2FO Early Pay Program is a simple, low-cost way for C2FO vendors to improve cash flow.`,
      ogDescription: props.ogDescription || `The C2FO Early Pay Program is a simple, low-cost way for C2FO vendors to improve cash flow.`,
      title: props.title || `C2FO Early Payment Program | C2FO`,
      connonicalUrl: props.connonicalUrl || `https://c2fo.com/`,
      digitalDataTitle: props.c2foDataPageTitle || "",
    }
  }

  render() {
    let segmentIo = '';
    var digitalData =
      `window.c2foData = {
      "digitalData": {
          "site": "c2focn",
          "section": "signin",
          "subSection": "registration",
          "pageTitle": "${this.props.digitalDataTitle}",
          "buyer": "${this.props.buyer.name}",
          "permalink": "${this.props.buyer.permalink}"
      }
  };`

    var adobeTarget =
      `// Add style for adobe flicker
    var style = document.createElement('style');
    style.id = 'buyer-microsite';
    style.innerHTML = "body {opacity: 0 !important}";
    document.getElementsByTagName('head')[0].appendChild(style);
    // Remove weird adobe flicker style
    setTimeout(function() {
        document.getElementById('buyer-microsite').remove();
    }, 2000);`;

    const dataDogRumSnippet = process.env.GATSBY_DATA_DOG_RUM ? `(function(h,o,u,n,d) {
      h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
      d=o.createElement(u);d.async=1;d.src=n
      n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
    })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
    DD_RUM.onReady(function() {
      DD_RUM.init({
        clientToken: 'pub50bd41385cc7bfca26dcd3918ef7cfa6',
        applicationId: '40d7f619-f40d-4385-86ed-53fc5100101c',
        site: 'datadoghq.com',
        service: 'china-buyer-microsites',
        env: '${process.env.GATSBY_DATA_DOG_RUM}',
        sampleRate: 100,
        sessionReplaySampleRate: 20,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
  
      DD_RUM.startSessionReplayRecording();
    })` : '// DATA_DOG_RUM not defined -  disabled';

    if (typeof window !== 'undefined') {
      segmentIo =
        !function () {
          var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
            analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]; analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (var t = 0; t < analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) { var n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(n, a); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.1.0";
            analytics.load("a3GkvedRT7nGFMDjPNqGQ3MHISWabwhH");
            analytics.page();
          }
        }();
    }

    return (
      <Helmet>
        <title>{`C2FO`}</title>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP" crossorigin="anonymous" />
        <meta name="description" content={this.state.description} />
        <link rel="canonical" href={this.state.connonicalUrl}></link>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content={this.state.title}></meta>
        <meta property="og:description" content={this.state.ogDescription}></meta>
        <meta property="og:url" content={this.state.connonicalUrl}></meta>
        <meta property="og:site_name" content={this.state.title}></meta>
        <meta name="google-site-verification" content="ZIxXuPRbzUh_5_F93RO9pSF3S94764TXgfkDdTT70go" />
        <script dangerouslySetInnerHTML={segmentIo} />
        <link rel="stylesheet" href={ isRebranded() ? "/css/base-rebrand.css" : "/css/base.css" } />
        <script src={process.env.GATSBY_ADOBE_SCRIPT} async></script>
        <script type="text/javascript">{digitalData}</script>
        <script type="text/javascript">{adobeTarget}</script>
        <script type="text/javascript">{dataDogRumSnippet}</script>
      </Helmet>
    );
  }
}

export default Head;
