import React, { Component } from 'react';
import headerStyles from './header.module.css';
import LanguageSelector from './language_selector';
import oldHeaderStyles from './header.module.css';
import newHeaderStyles from './header_rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeClass: '',
      badLogo: false
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {

        var scrollClass = 'c2fo';
        if (window.scrollY > 0) {
          scrollClass = true;
        } else {
          scrollClass = false;
        }
        this.setState({
          activeClass: scrollClass
        })

      });
    }
  }

  componentDidMount() {
    const buyerName = this.props.buyer.name;

    if (buyerName === 'Chevron') {
      this.setState({
        badLogo: true
      })
    }
  }

  render() {
    const headerStyles = isRebranded() ? newHeaderStyles : oldHeaderStyles;
    const buyer = this.props.buyer;
    const stateClass = this.state.activeClass ? headerStyles.c2foHeader__small : '';
    var displayPhone = this.props.phone;
    var linkedPhone = displayPhone.replace(/^(\+)|\D/g, "$1");
    const login = this.props.content.login;
    const getStarted = this.props.content.getStarted;
    var isDabur = buyer.permalink === 'dabur';
    var isNaturelle = buyer.permalink === 'naturelle';
    var isEscorts = buyer.permalink === 'escorts';
    var isUnominda = buyer.permalink === 'unominda';
    var isNoidaBuyer = isDabur || isNaturelle || isEscorts || isUnominda;

    const SupportPhone = () => {
      if (buyer.name.toLowerCase() === 'walmart' && this.props.locale.toLowerCase() ==='es-mx' )
      {
        displayPhone = "+52 800 083 0162"; 
        linkedPhone = "+528000830162";
        
        const chileDisplayPhone = "+56 800 681 015";
        const chileLinkedPhone = "+56800681015"
        return (<div style={{marginLeft: "15px", paddingLeft: "5px"}}>
                  <div className={headerStyles.phone_p}>México: </div> 
                  <a href={`tel:${linkedPhone}`}>{displayPhone}</a> 
                  <div className={headerStyles.phone_p}>Chile: </div>
                  <a href={`tel:${chileLinkedPhone}`}>{chileDisplayPhone}</a> 
                </div>);
      }
      if (isNoidaBuyer && this.props.locale === 'en-IN') {
        linkedPhone = '+919555599222';
        displayPhone = '+91 95555 99222';
      }
      if (buyer.name.toLowerCase() === "morrisons") {
        displayPhone = "+44 203 929 3422";
        linkedPhone = "+442039293422";
      }

      return <a href={`tel:${linkedPhone}`}>{displayPhone}</a>;
    };

    return (
      <header className={`${headerStyles.c2foContainer} ${stateClass}`}>
        <div className={headerStyles.c2foContainer__inner}>
          <div className={headerStyles.c2foHeader__left}>
            <a href="/">
            {this.state.badLogo ?
            <img className={headerStyles.logo__noWidth} src={buyer.logo} alt={buyer.name} />
              : <img src={buyer.logo} alt={buyer.name} />
            }
            </a>
          </div>
          <div className={headerStyles.c2foHeader__right}>
            <LanguageSelector 
              handler={this.props.handler.bind(this)}
              locale={this.props.locale}
              buyer={buyer}
              location={this.props} />
            <SupportPhone />
            {this.props.hideLogin ? '' :
            <a id="login-link" href="https://app.c2fo.com/login">{login}</a>
            }
            <a className={headerStyles.c2foBtn} href="#get-started">{getStarted}</a>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;