import Cookies from 'js-cookie';

const organicReferrals = [
    '360.cn',
    'about',
    'alice',
    'aol',
    'ask',
    'avg',
    'babylon',
    'baidu',
    'biglobe',
    'bing',
    'cnn',
    'comcast',
    'conduit',
    'daum',
    'eniro',
    'go.mail.ru',
    'google',
    'goo.ne',
    'images.google',
    'incredimail',
    'kvasir',
    'live',
    'lycos',
    'mamma',
    'msn',
    'najdi',
    'naver',
    'netscape',
    'onet',
    'ozu',
    'pchome',
    'rakuten',
    'rambler',
    'search.centrum.cz',
    'search-results',
    'seznam',
    'startsiden',
    'terra',
    'virgilio',
    'voila',
    'wp',
    'yahoo',
    'yam',
    'yandex'
]

export const getParams = (url) => {
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    if (!query) {
        getUTMReferrer();
    }
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        Cookies.set(pair[0], pair[1]);
    }
}

const getUTMReferrer = () => {
    const referral = document.referrer;
    let content;
    let source;
    content = splitOutContent(referral);
    source = splitReferralUrl(referral);
    if (!referral) {
        Cookies.set('utm_source', '(direct)');
        Cookies.set('utm_medium', '(none)');
    } else {
        if(checkOrganicReferral(source)) {
            Cookies.set('utm_source', source);
            Cookies.set('utm_medium', 'organic');
        } else if(!checkOrganicReferral(source)) {
            Cookies.set('utm_source', source);
            Cookies.set('utm_medium', 'referral');
        }
        if (content) {
            Cookies.set('utm_content', content);
        }
    }
}

const splitReferralUrl = (referral) => {
    if (referral.indexOf('//') > -1) {
        return referral.split('/')[2];
    } else {
        return referral.split('/')[0];
    }
}

const splitOutContent = (referral) => {
    if (referral.length > 200) {
        referral = referral.substring(0, 200);
    }

    if (referral.indexOf('//') > -1) {
        return referral.split('/')[3];
    }
}

const checkOrganicReferral=(referral) => organicReferrals.find(organic => {
    return organic === referral;
});