import React, { Component } from "react";
import oldHeaderStyles from './header.module.css';
import newHeaderStyles from './header_rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';

import { createMemoryHistory } from "history";
import { createBrowserHistory } from "history";
const queryString = require('querystring');

const headerStyles = isRebranded() ? newHeaderStyles : oldHeaderStyles;

if (typeof window !== 'undefined') {
  var history = createBrowserHistory();
} else {
  history = createMemoryHistory();
}


class LanguageSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: 'zh-CN',
    }

  }


  // componentDidMount() {
  //   this.getUserLanguagePreference();
  // }

  setBuyerLanguageDefaults() {
    var currentBuyer = this.props.buyer.permalink;

    if (currentBuyer === 'newcrest' || currentBuyer === 'pacificnational') {
      this.setState({
        value: 'en-AU'
      })
      this.props.handler('language', 'en-AU');
    } else if (currentBuyer === 'jumbo') {
      this.setState({
        value: 'nl'
      })
      this.props.handler('language', 'nl');
    } else if (currentBuyer === 'tco') {
      this.setState({
        value: 'ru-RU'
      })
      this.props.handler('language', 'ru');
    } else if (currentBuyer === 'asianpaints' ||
      currentBuyer === 'balco' ||
      currentBuyer === 'bigbasket' ||
      currentBuyer === 'ceat' ||
      currentBuyer === 'cipla' ||
      currentBuyer === 'crompton' ||
      currentBuyer === 'cumi' ||
      currentBuyer === 'emami' ||
      currentBuyer === 'futurelifestylefashions' ||
      currentBuyer === 'metrocashandcarry' ||
      currentBuyer === 'nerolac' ||
      currentBuyer === 'raychemrpg' ||
      currentBuyer === 'tgbl' ||
      currentBuyer === 'inorbit' ||
      currentBuyer === 'astral') {
      this.setState({
        value: 'en-IN'
      })
      this.props.handler('language', 'en-IN');
    }
  }

  getLanguageParam(supportedLanguages) {

    const langParam = this.props.location.params.search.split('?')[1];
    const params = queryString.parse(langParam);

    var isSupportedLangugage = supportedLanguages.find(language => language === params.lang);
    if (!isSupportedLangugage) {
      return false;
    } else {
      this.setState({
        value: params.lang
      })
      this.props.handler('language', params.lang);
    }

  }

  /**
   * Checks users browser language and populates select with that option
   *  If User has french set in any country, returns fr-FR option
   *  Updates prop handler language to update content on page
   */
  getUserLanguagePreference() {
    const userLanguage = navigator.language;
    let supportedLanguages = ['en-GB','fr-FR', 'de', 'en-IN', 'en-AU', 'nl', 'de-DE', 'zh-CN', 'zh-TW', 'es-ES', 'it-IT', `ru-RU`, 'tr-TR'];    let isFrench = userLanguage.substr(0,2) === 'fr';
    let isGerman = userLanguage.substr(0,2) === 'de';
    let isSpanish = userLanguage.substr(0,2) === 'es';
    let isItalian = userLanguage.substr(0,2) === 'it';
    let isChinese = userLanguage.substr(0,2) === 'zh' && userLanguage !== 'zh-TW';

    console.log('test',navigator.language)

    var isSupportedLangugage = supportedLanguages.find( language => language === userLanguage );
    if (!isSupportedLangugage && !isFrench && !isChinese && !isGerman && !isSpanish && !isItalian) {
      this.setState({
        value: 'en-US'
      })
    } else if (isFrench) {
      this.setState({
        value: 'fr-FR'
      })
      this.props.handler('language', 'fr-FR');
    } else if (isChinese) {
      this.setState({
        value: 'zh-CN'
      })
      this.props.handler('language', 'zh-CN');
    } else if (isGerman) {
      this.setState({
        value: 'de'
      })
      this.props.handler('language', 'de');
    } else if (isSpanish) {
      this.setState({
        value: 'es-ES'
      })
      this.props.handler('language', 'es-ES');
    } else if (isItalian) {
      this.setState({
        value: 'it-IT'
      })
      this.props.handler('language', 'it-IT');
    } else {
      this.setState({
        value: userLanguage
      })
    }
    this.props.handler('language', userLanguage);

    this.setBuyerLanguageDefaults();
    this.getLanguageParam(supportedLanguages);
  }

  handleChange(e) {
    this.props.handler('language', e.target.value);
    this.setState({ value: e.target.value });
    history.push(`${e.target.value}`);
  }

  render() {
    let language;

    if (typeof window !== 'undefined') {
      language = window.navigator.language;
    }

    return (
      <div className={headerStyles.c2foSelect__arrow}>
        <select className={headerStyles.c2foLanguage__select} value={this.state.value} onChange={this.handleChange.bind(this)}>
          <option value="en-US">English (US)</option>
          <option value="en-GB">English (UK)</option>
          <option value="en-IN">English (India)</option>
          <option value="en-AU">English (AUS)</option>
          <option value="es-ES">Español</option>
          <option value="fr-FR">Français</option>
          {language === 'de-DE' ?
            <option value="de-DE">Deutsch</option>
            :  <option value="de">Deutsch</option>

          }
          {/* <option value="de">Deutsch</option> */}
          <option value="it-IT">Italiano</option>
          <option value="nl">Dutch</option>
          <option value="ru-RU">Russian</option>
          <option value="tr-TR">Türkçe</option>
          <option value="zh-CN">简体中文</option>
          <option value="zh-TW">繁體中文</option>
        </select>
      </div>
    )

  }

}
export default LanguageSelector;